import React, { useLayoutEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import EncodeUrl from '@/utils/encode';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import { nameRouterSearch } from '@/utils/constants';

const OwlCarousel = dynamic(() => import(`@/componentWebs/Global/OwlCarousel`), {
  ssr: false,
  loading: () => null,
});

const PAGE_SIZE = 3;

function NewStorys({ name, hiddenTab, data, dispatch, link, filter }) {
  const newReiseImg = [156, 156, 156];
  const [typeStories, setTypeStories] = useState([]);
  const [activeType, setActiveType] = useState('');

  const fetchTypeStories = () => {
    dispatch({
      type: 'webs/fetchTypeStory',
      payload: {
        filter: JSON.stringify({
          status: 1,
        }),
        range: JSON.stringify([0, PAGE_SIZE - 1]),
        sort: JSON.stringify(['id', 'asc']),
      },
      callback: response => {
        if (response.success) {
          const { list } = response.result;
          setTypeStories(list || []);
          setActiveType(list?.[0]?.id || '');
        }
      },
    });
  };

  useLayoutEffect(() => {
    if (filter) fetchTypeStories();

  }, []);

  const handleClickTypeTab = (id) => {
    setActiveType(id)
  }

  const dataDisplay = filter ? (data?.filter(item => Number(item.storyTypes?.id) === Number(activeType)) || []) : [...data]


  return (
    <div className="block-update module-box bg-white  pdd-6">
      <div className="module-header  pdd-6 flex-b-c">
        <h3 className="module-title">{name}</h3>
        <a href={link === 'auto' ? `/tim-kiem-tong-hop?${nameRouterSearch.typeStory}=${activeType}` : link} className="pull-right">
          Xem thêm <i className="fa fa-angle-right" aria-hidden="true" />
        </a>
      </div>
      {!hiddenTab && (
        <div className="module-tab moduleTab ">
          <nav className="btn-group btn-tabs flex nav-tabs">
            {typeStories?.map(item => (
              <a
                href="javascript:void(0);"
                className={`btn-tab btn-tab-1 text-center ${activeType === item.id ? 'active' : ''} `}
                key={item.id}
                onClick={() => handleClickTypeTab(item.id)}
              >
                {item.name}
              </a>
            ))}
          </nav>
        </div>
      )}

      <div className="tab-1 tab-list  mrgtop-6">
        {dataDisplay?.length > 0 && (
          <OwlCarousel
            //   className="section-khachHang-owlCarousel"
            items={4}
            margin={15}
            dots={false}
            nav={false}
            key={activeType || Math.random()}
            // autoplay={true}
            // autoplayHoverPause={true}
            // autoplayTimeout={3000}
            slideBy={4}
          // loop
          >
            {dataDisplay?.map(item => (
              <div className="item item-0" key={item.id}>
                <a href={`doc-truyen/${EncodeUrl(item.name)}/${item.id}`} className="story-image  ">
                  <img
                    src={getResponsiveImage(item?.images?.file, newReiseImg)}
                    height="105px"
                    width="100%"
                    alt={item.name}
                  />
                </a>
                <div className="story-detail ">
                  <p className="ttv-story-title-block"> {item.name}</p>
                </div>
              </div>
            ))}

            {/* <div className="item item-0">
              <a href="/doc-truyen/34895" className="story-image  ">
                <img
                  src="https://nae.vn/ttv/ttv/public/images/story/60a219aab61328e0602d411c7c5d834f51c1d96b2f91d441765fbec3afdfeab2.jpg"
                  height="105px"
                  width="100%"
                  onerror="this.src='/images/default-book.png'"
                />
              </a>
              <div className="story-detail ">
                <p className="ttv-story-title-block">Quyền Du: Thụy Long Chi Nộ</p>
              </div>
            </div> */}
          </OwlCarousel>
        )}
      </div>
    </div>
  );
}

export default React.memo(NewStorys);
