/* eslint-disable prefer-destructuring */
import React from 'react';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import page from '@/layouts/page';
import Head from '@/componentWebs/head';
import NewStorys from '@/componentWebs/NewStorys';
import StoryMobile2 from '@/componentWebs/StoryMobile2';
import EncodeUrl from '@/utils/encode';
import Cookie from 'js-cookie';
import { fnKhongDau, getQueryString2, renderLinkImgAvatar } from '@/utils/utils';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import { isMobile as isMobileClient, getSelectorsByUserAgent } from 'react-device-detect';
import moment from 'moment';
import {
  convertRouterConverter,
  convertRouterStory,
  convertRouterCategory,
  convertRouterChapter,
  convertRouterAuthor,
} from '@/utils/routerConvert';
import {
  nameRouterSearch,
  categoriesNewsId,
  adsPositionsId,
  adsPositionsCenterId,
  adsPositionsCenterMobileId
} from '@/utils/constants';
import { unionBy } from 'lodash'
import { useQueries, QueryClient, dehydrate } from 'react-query';
import { queryListConverters, queryTopAuthor, queryListStartHome, queryStoryFollowStart, queryNewViewStoriesStart, queryArticleAll, queryAd } from '@/services/websRedux';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'


const PAGE_SIZE_NEWVIEWSTORIES = 14;

const OwlCarousel = dynamic(() => import(`@/componentWebs/Global/OwlCarousel`), {
  ssr: false,
  loading: () => null,
});

const Carousel3D = dynamic(() => import(`@/componentWebs/Carousel3D`), {
  ssr: false,
  loading: () => null,
});

const { publicRuntimeConfig } = getConfig();
const UseQuery = (props) => {
  return props.children(useQueries(props.data))
}
const icon = [
  { name: 'Tiên hiệp', icon: '' },
  { name: 'Huyền huyễn', icon: '' },
  { name: 'Đô thị', icon: '' },
  { name: 'Khoa huyễn', icon: '' },
  { name: 'Kỳ huyễn', icon: '' },
  { name: 'Võ hiệp', icon: '' },
  { name: 'Lịch sử', icon: '' },
  { name: 'Đồng nhân', icon: '' },
  { name: 'Quân sự', icon: '' },
  { name: 'Võng du', icon: '' },
  { name: 'Quan trường', icon: '' },
  { name: 'Linh dị', icon: '' },
  { name: 'Ngôn tình', icon: '' },
  { name: 'Tất cả', icon: '' }
]
// @page
@connect(({ webs }) => ({
  webs,
}))
class Index extends React.Component {
  static async getInitialProps(ctx) {
    const { store, dispatch, query, req } = ctx;
    const userAgent = ctx?.req?.headers?.['user-agent'];
    const { isMobile } = getSelectorsByUserAgent(userAgent) || {};
    const cookieServer = getQueryString2(req?.headers?.cookie || '');
    const token = cookieServer.token;
    const queryClient = new QueryClient()
    // Truyện theo doi
    function fetchStoryFollow() {
      const param = {
        range: JSON.stringify([0, PAGE_SIZE_NEWVIEWSTORIES]),
        sort: JSON.stringify(['id', 'desc']),
      };
      return queryStoryFollowStart(param, token)
    }
    // Tạp văn
    function fetchNews() {
      const param = {
        filter: {
          status: '1',
          categoriesId: categoriesNewsId,
        },
        sort: ['createDate', 'DESC'],
        range: [0, 6],
        attributes: 'id,status,title,createDate',
      };
      return queryArticleAll(param)
    }
    //silde
    function fetchSildes(positionID) {
      const param = {
        filter: {
          status: '1',
          adsPositionsId: positionID,
        },
        range: [0, 4],
      };
      return queryAd(param)
    }
    // Truyện mới xem
    function fetchNewViewStories() {
      const param = {
        range: JSON.stringify([0, PAGE_SIZE_NEWVIEWSTORIES - 1]),
        sort: JSON.stringify(['dateUpdated', 'desc']),
      };
      return queryNewViewStoriesStart(param, token)
    }
    function fetchStories(typeApi) {
      const defaultParam = {
      };
      return queryListStartHome(defaultParam, typeApi)
    };

    function fetchTopAuthor() {
      const defaultParam = {
        filter: JSON.stringify({
          status: '1',
        }),
        range: JSON.stringify([0, 9]),
      };
      return queryTopAuthor(defaultParam)
    }

    function fetchConveter() {
      const defaultParam = {
        filter: JSON.stringify({
          status: '1',
        }),
        range: JSON.stringify([0, 9]),
        attributes: `id,name,images,countChapter`,
      };
      return queryListConverters(defaultParam)
    }
    await Promise.all([
      queryClient.prefetchQuery('fetchNewViewStories', fetchNewViewStories),
      queryClient.prefetchQuery('fetchStoryFollow', fetchStoryFollow),
      queryClient.prefetchQuery('fetchNews', fetchNews),
      queryClient.prefetchQuery('fetchSildes', () => fetchSildes(adsPositionsId)),
      queryClient.prefetchQuery('fetchSildes2', () => fetchSildes(isMobile ? adsPositionsCenterMobileId : adsPositionsCenterId)),
      queryClient.prefetchQuery('fetchTopAuthor', fetchTopAuthor),
      queryClient.prefetchQuery('fetchConveter', fetchConveter),
      queryClient.prefetchQuery('view', () => fetchStories('view')),
      queryClient.prefetchQuery('nominate', () => fetchStories('nominate')),
      queryClient.prefetchQuery('like', () => fetchStories('like')),
      queryClient.prefetchQuery('follow', () => fetchStories('follow')),
      queryClient.prefetchQuery('new', () => fetchStories('new')),
      queryClient.prefetchQuery('update', () => fetchStories('update')),
      queryClient.prefetchQuery('complete', () => fetchStories('complete'))
    ]);
    const resultPromise = dehydrate(queryClient)?.queries || []
    const dataNewViewStories = resultPromise?.length > 0 && resultPromise[0]?.state?.data?.result?.list || [];
    const dataStoryFollow = resultPromise?.length > 1 && resultPromise[1]?.state?.data?.result?.list || [];
    const dataNews = resultPromise?.length > 2 && resultPromise[2]?.state?.data?.result?.list || [];
    const dataSlides = resultPromise?.length > 3 && resultPromise[3]?.state?.data?.result?.list || [];
    const dataSlidesCenter = resultPromise?.length > 4 && resultPromise[4]?.state?.data?.result?.list || [];


    const topAuthors = resultPromise?.length > 5 && resultPromise[5]?.state?.data?.result?.list || []
    const topConverters = resultPromise?.length > 6 && resultPromise[6]?.state?.data?.result?.list || []
    const viewStories = resultPromise?.length > 7 && resultPromise[7]?.state?.data?.data?.rows || []
    const nominateStories = resultPromise?.length > 8 && resultPromise[8]?.state?.data?.data?.rows || []
    const likeStories = resultPromise?.length > 9 && resultPromise[9]?.state?.data?.data?.rows || []
    const followStories = resultPromise?.length > 10 && resultPromise[10]?.state?.data?.data?.rows || []
    const newStories = resultPromise?.length > 11 && resultPromise[11]?.state?.data?.data?.rows || []
    const updateStories = resultPromise?.length > 12 && resultPromise[12]?.state?.data?.data?.rows || []
    const completeStories = resultPromise?.length > 13 && resultPromise[13]?.state?.data?.data?.rows || []
    return {
      resultPromise,
      dataNewViewStories,
      dataStoryFollow,
      query,
      dataNews,
      dataSlides,
      dataSlidesCenter,
      token,
      topAuthors,
      topConverters,
      viewStories,
      nominateStories,
      likeStories,
      followStories,
      newStories,
      updateStories,
      completeStories,
      newViewStories: cookieServer.newViewStories,
    };
  }

  constructor(props) {
    super(props);
    const { dataNewViewStories, dataStoryFollow, token, newViewStories } = props;
    this.state = {
      dataNewViewStories,
      dataStoryFollow,
      cookieToken: token,
      cookieDataNewViewStories: !token && newViewStories && JSON.parse(decodeURIComponent(newViewStories))?.map(item => ({
        ...item,
        storiesId: item.stories?.id || ''
      })) || [],
    };
  }

  componentDidMount() {
    const { queryClient } = this.props
    const localStoragePersistor = createWebStoragePersistor({ storage: window.localStorage })

    persistQueryClient({
      queryClient,
      persistor: localStoragePersistor,
    })
  }


  render() {

    const {
      dataSite,
      dispatch,
      dataNews,
      dataSlides,
      currentUser,
      dataSlidesCenter,
      menuParentCategories,
      topAuthors,
      topConverters,
      viewStories,
      nominateStories,
      likeStories,
      followStories,
      newStories,
      updateStories,
      completeStories,
      resultPromise
    } = this.props;
    const {
      dataNewViewStories,
      dataStoryFollow,
      cookieToken,
      cookieDataNewViewStories,
    } = this.state;
    let url;
    if (typeof window !== 'undefined') {
      url = window.location.href;
    }
    // Chưa đăng nhập, list truyện mới xem lấy từ cookie
    const newDataViewStories = [...cookieDataNewViewStories, ...dataNewViewStories].slice(
      0,
      PAGE_SIZE_NEWVIEWSTORIES
    )
    const newReiseImg = [156, 156, 156];
    // console.log(resultPromise);
    return (

      <React.Fragment>
        <Head
          key="home"
          title={`${dataSite?.name || ''} - Đọc truyện online, đọc truyện hay`}
          dataSite={dataSite}
          ogImage={dataSlides?.length > 0 && dataSlides[0]?.contents[0]?.file}
          url={url || ''}
          keywords={dataSite?.seoKeywords || 'Loading'}
          description={dataSite?.seoDescriptions || 'Loading'}
        />
        <div className="h-mt">
          <div className="focus-wrap box-center mb40 cf ">
            <div className="classify-list fl so-awesome" id="classify-list">
              <dl>
                {menuParentCategories?.map((item, index) => (
                  <dd
                    key={item.id}
                    // className={[2,3,6,7,10,11].includes(index) && 'even'}
                    style={{ marginBottom: 0, background: ((index + 2) % 4 === 0 || (index + 1) % 4 === 0) && '#fff' }}
                  >
                    <a
                      href={convertRouterCategory(item.urlSlugs, item.id)}
                    >
                      <cite className="flex-a-c" style={{ display: 'flex' }}>
                        <em className="iconfont">{icon?.find(ico => fnKhongDau(ico.name) === fnKhongDau(item.name))?.icon || ''}</em>
                        <span className="info" style={{ float: 'unset' }}>
                          <i>{item.name}</i>
                          <b>{item.totalStories}</b>
                        </span>
                      </cite>
                    </a>
                  </dd>
                ))}
                <dd style={{ marginBottom: 0 }}>
                  <a href="/tim-kiem-tong-hop">
                    <cite className="flex-a-c" style={{ display: 'flex' }}>
                      <em className="iconfont">{icon?.find(ico => ico.name === 'Tất cả')?.icon}</em>
                      <span className="info" style={{ float: 'unset' }}>
                        <i>Tất cả</i>
                      </span>
                    </cite>
                  </a>
                </dd>
              </dl>
            </div>
            <div className="focus-box fl">
              <div className="focus-slider">
                <div id="cbp-contentslider" className="cbp-contentslider">
                  {dataSlides?.length > 0 && (
                    <OwlCarousel
                      items={1}
                      margin={15}
                      autoplay={true}
                      autoplayHoverPause={true}
                      autoplayTimeout={3000}
                      slideBy={1}
                      loop
                      dots
                    >
                      {dataSlides?.map(item => (
                        <a href={item.url} target="_blank" key={item.id}>
                          <img
                            src={getResponsiveImage(item?.contents?.[0]?.file, [673, 673, 673])}
                            alt={item.title}
                          />
                        </a>
                      ))}
                    </OwlCarousel>
                  )}
                  <nav className="thumb lbf-button-group nav" id="thumb">
                    {dataSlides?.map(item => (
                      <a href={item.url} className="icon-wolf">
                        <span>{item.title}</span>
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="focus-img cf">
                <ul>
                  {topConverters.slice(0, 3)?.map((item, index) => (
                    <li className="first" key={item.id}>
                      <div />
                      <a href={convertRouterConverter(item.id)}>
                        <img src={item?.image?.file && getResponsiveImage(item?.image?.file, newReiseImg) || '/static/truyen/images/default-avatar.jpeg'} />
                      </a>
                      <p>{item.fullname}</p>
                      <span>
                        No.{index + 1}
                        <br />
                        Converter
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="notice-wrap fr">
              <div className="notice" id="notice">
                <h3>
                  <a href="javascript:void(0);" data-eid="qd_A93">
                    Tạp văn
                  </a>
                </h3>
                <div className="notice-list">
                  <ul>
                    {dataNews.slice(0, 7)?.map((item, index) => (
                      <li className={!(index % 2) && "odd"} key={item.id}>
                        <a href={`/tap-van/${EncodeUrl(item.title)}/${item.id}`} target="_blank">
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="index-two-wrap box-center mb40 cf">
            <div className="book-list-wrap mr30 fl">
              <h3 className="wrap-title lang">Truyện mới xem</h3>
              <div className="book-list">
                <ul>
                  {newDataViewStories?.map((item, index) => {
                    const nameStory = item.stories?.name || item.name || '';
                    // console.log('item', item, nameStory);
                    // console.log('link', `/doc-truyen/${EncodeUrl(nameStory)}/${item.stories?.id}`);

                    return (
                      <li key={index}>
                        <strong>
                          <a
                            className="name"
                            href={`/doc-truyen/${EncodeUrl(nameStory)}/${item.stories?.id}`}
                            title={nameStory}
                          >
                            {nameStory}
                          </a>
                        </strong>
                        <span className="rec">Chương {item.storyChapters?.number || 1}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="edit-rec-wrap fl">
              <h3 className="wrap-title lang">Biên tập viên đề cử</h3>
              <div className="focus-today cf">
                <div className="slider-wrap fl">
                  <Carousel3D data={viewStories?.slice(0, 6)} />
                  {/* <a style={{ display: 'block', width: '100%', height: '100%' }}>
            <img
              src={getResponsiveImage(viewStories[0]?.images?.file, newReiseImg)}
              alt={viewStories[0]?.name}
              className="objc"
            />
          </a> */}


                </div>
              </div>
              <div className="edit-rec">
                <ul className="edit-rec-list bd cf">
                  {viewStories?.slice(6, 9)?.map(item => (
                    <li key={item.id}>
                      <h3>
                        <a href={convertRouterStory(item.name, item.id)}>{item.name}</a>
                      </h3>
                      <em className="total" style={{ height: 'auto' }}>
                        <cite>{item.countChapter}</cite>
                        <i>chương</i>
                      </em>
                      <p>{item.description}</p>
                    </li>
                  ))}
                </ul>
                <div className="rel">
                  <p className="line" />
                </div>
                <ul className="edit-rec-list bd cf">
                  {viewStories?.slice(9, 12)?.map(item => (
                    <li key={item.id}>
                      <h3>
                        <a href={convertRouterStory(item.name, item.id)}>{item.name}</a>
                      </h3>
                      <em className="total">
                        <cite>{item.countChapter}</cite>
                        <i>chương</i>
                      </em>
                      <p>{item.description}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="book-list-wrap fr">
              <h3 className="wrap-title lang">
                Đang theo dõi{' '}
                <a
                  href={`${cookieToken ? `trang-ca-nhan/${EncodeUrl(currentUser?.username)}?type=7` : '#'
                    } `}
                  target="_blank"
                >
                  Tất cả
                </a>
              </h3>
              <div className="book-list">
                <ul>
                  {dataStoryFollow?.map(item => {
                    const nameStory = item.stories?.name;
                    return (
                      <li key={item.id}>
                        <strong>
                          <a
                            className="name"
                            href={`/doc-truyen/${EncodeUrl(nameStory)}/${item.storiesId}`}
                            title={nameStory}
                          >
                            {nameStory}
                          </a>
                        </strong>
                        <span className="rec">{item.stories?.countChapter} chương</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {dataSlidesCenter[0] && (
            <div className="banner-wrap box-center mb30" id="banner-two" data-l1={31}>
              <a
                href={dataSlidesCenter[0].url}
                target="_blank"
                data-eid="qd_A116"
                data-qd_dd_p1={1}
                style={{ display: 'block' }}
              >
                <img
                  src={getResponsiveImage(dataSlidesCenter[0]?.contents?.[0]?.file, [
                    1200,
                    1200,
                    1200,
                  ])}
                />
                <span className="op-tag" />
              </a>
            </div>
          )}

          <div className="rank-wrap box-center mb20" id="numero3-no12s1">
            <div id="numero3">
              <div id="rank-list-row" className="rank-list-row cf mb20">
                <div className="rank-list">
                  <h3 className="wrap-title lang">
                    Đề cử
                    <a href={`/tim-kiem-tong-hop?${nameRouterSearch.rank}=nominate`}>Tất cả</a>
                  </h3>
                  <div className="book-list">
                    <ul>
                      {nominateStories.slice(0, 10)?.map((item, index) => {
                        if (index === 0)
                          return (
                            <li className="unfold" key={item.id}>
                              <div className="book-wrap cf">
                                <div className="book-info fl">
                                  <h3>NO.1</h3>
                                  <h4>
                                    <a
                                      href={convertRouterStory(item.name, item.id)}
                                      title={item.name}
                                    >
                                      {item.name}
                                    </a>
                                  </h4>
                                  <p className="digital">
                                    <em>{item.countNominate}</em>Đề cử
                                  </p>
                                  <p className="author">
                                    <a
                                      className="type"
                                      href={convertRouterCategory(
                                        item.storyCategoriesParent?.urlSlugs,
                                        item.storyCategoriesParent?.id
                                      )}
                                    >
                                      {item.storyCategoriesParent?.name}
                                    </a>
                                    <i>·</i>
                                    <a
                                      className="writer"
                                      href={convertRouterAuthor(item.storyAuthors?.id)}
                                    >
                                      {item.storyAuthors?.name}
                                    </a>
                                  </p>
                                </div>
                                <div className="book-cover">
                                  <a
                                    className="link"
                                    href={convertRouterStory(item.name, item.id)}
                                    title={item.name}
                                  >
                                    <img
                                      src={getResponsiveImage(item?.images?.file, newReiseImg)}
                                      alt={item.name}
                                    />
                                  </a>
                                  <span />
                                </div>
                              </div>
                            </li>
                          );

                        return (
                          <li data-rid={2} key={item.id}>
                            <div className="num-box">
                              <span className="num2">{index + 1}</span>
                            </div>
                            <div className="name-box">
                              <a
                                className="name"
                                href={convertRouterStory(item.name, item.id)}
                                title={item.name}
                              >
                                {item.name}
                              </a>
                              <i className="total">{item.countNominate}</i>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="rank-list ">
                  <h3 className="wrap-title lang">
                    Xem nhiều
                    <a href={`/tim-kiem-tong-hop?${nameRouterSearch.rank}=view`}>Tất cả</a>
                  </h3>
                  <div className="book-list">
                    <ul>
                      {viewStories?.slice(0, 10)?.map((item, index) => {
                        if (index === 0)
                          return (
                            <li className="unfold" key={item.id}>
                              <div className="book-wrap cf">
                                <div className="book-info fl">
                                  <h3>NO.1</h3>
                                  <h4>
                                    <a
                                      href={convertRouterStory(item.name, item.id)}
                                      title={item.name}
                                    >
                                      {item.name}
                                    </a>
                                  </h4>
                                  <p className="digital">
                                    <em>{item.view}</em>Lượt xem
                                  </p>
                                  <p className="author">
                                    <a
                                      className="type"
                                      href={convertRouterCategory(
                                        item.storyCategoriesParent?.urlSlugs,
                                        item.storyCategoriesParent?.id
                                      )}
                                    >
                                      {item.storyCategoriesParent?.name}
                                    </a>
                                    <i>·</i>
                                    <a
                                      className="writer"
                                      href={convertRouterAuthor(item.storyAuthors?.id)}
                                    >
                                      {item.storyAuthors?.name}
                                    </a>
                                  </p>
                                </div>
                                <div className="book-cover">
                                  <a
                                    className="link"
                                    href={convertRouterStory(item.name, item.id)}
                                    title={item.name}
                                  >
                                    <img
                                      src={getResponsiveImage(item?.images?.file, newReiseImg)}
                                      alt={item.name}
                                    />
                                  </a>
                                  <span />
                                </div>
                              </div>
                            </li>
                          );

                        return (
                          <li data-rid={2} key={item.id}>
                            <div className="num-box">
                              <span className="num2">{index + 1}</span>
                            </div>
                            <div className="name-box">
                              <a
                                className="name"
                                href={convertRouterStory(item.name, item.id)}
                                title={item.name}
                              >
                                {item.name}
                              </a>
                              <i className="total">{item.view}</i>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="rank-list">
                  <h3 className="wrap-title lang">
                    Yêu thích
                    <a href={`/tim-kiem-tong-hop?${nameRouterSearch.rank}=like`}>Tất cả</a>
                  </h3>
                  <div className="book-list">
                    <ul>
                      {likeStories.slice(0, 10)?.map((item, index) => {
                        if (index === 0)
                          return (
                            <li className="unfold" key={item.id}>
                              <div className="book-wrap cf">
                                <div className="book-info fl">
                                  <h3>NO.1</h3>
                                  <h4>
                                    <a
                                      href={convertRouterStory(item.name, item.id)}
                                      title={item.name}
                                    >
                                      {item.name}
                                    </a>
                                  </h4>
                                  <p className="digital">
                                    <em>{item.countLike}</em>Lượt thích
                                  </p>
                                  <p className="author">
                                    <a
                                      className="type"
                                      href={convertRouterCategory(
                                        item.storyCategoriesParent?.urlSlugs,
                                        item.storyCategoriesParent?.id
                                      )}
                                    >
                                      {item.storyCategoriesParent?.name}
                                    </a>
                                    <i>·</i>
                                    <a
                                      className="writer"
                                      href={convertRouterAuthor(item.storyAuthors?.id)}
                                    >
                                      {item.storyAuthors?.name}
                                    </a>
                                  </p>
                                </div>
                                <div className="book-cover">
                                  <a
                                    className="link"
                                    href={convertRouterStory(item.name, item.id)}
                                    title={item.name}
                                  >
                                    <img
                                      src={getResponsiveImage(item?.images?.file, newReiseImg)}
                                      alt={item.name}
                                    />
                                  </a>
                                  <span />
                                </div>
                              </div>
                            </li>
                          );

                        return (
                          <li data-rid={2} key={item.id}>
                            <div className="num-box">
                              <span className="num2">{index + 1}</span>
                            </div>
                            <div className="name-box">
                              <a
                                className="name"
                                href={convertRouterStory(item.name, item.id)}
                                title={item.name}
                              >
                                {item.name}
                              </a>
                              <i className="total">{item.countLike}</i>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="rank-list mr0">
                  <h3 className="wrap-title lang">
                    Theo dõi nhiều
                    <a href={`/tim-kiem-tong-hop?${nameRouterSearch.rank}=follow`}>Tất cả</a>
                  </h3>
                  <div className="book-list">
                    <ul>
                      {followStories.slice(0, 10)?.map((item, index) => {
                        if (index === 0)
                          return (
                            <li className="unfold" key={item.id}>
                              <div className="book-wrap cf">
                                <div className="book-info fl">
                                  <h3>NO.1</h3>
                                  <h4>
                                    <a
                                      href={convertRouterStory(item.name, item.id)}
                                      title={item.name}
                                    >
                                      {item.name}
                                    </a>
                                  </h4>
                                  <p className="digital">
                                    <em>{item.countFollow}</em>Lượt theo dõi
                                  </p>
                                  <p className="author">
                                    <a
                                      className="type"
                                      href={convertRouterCategory(
                                        item.storyCategoriesParent?.urlSlugs,
                                        item.storyCategoriesParent?.id
                                      )}
                                    >
                                      {item.storyCategoriesParent?.name}
                                    </a>
                                    <i>·</i>
                                    <a
                                      className="writer"
                                      href={convertRouterAuthor(item.storyAuthors?.id)}
                                    >
                                      {item.storyAuthors?.name}
                                    </a>
                                  </p>
                                </div>
                                <div className="book-cover">
                                  <a
                                    className="link"
                                    href={convertRouterStory(item.name, item.id)}
                                    title={item.name}
                                  >
                                    <img
                                      src={getResponsiveImage(item?.images?.file, newReiseImg)}
                                      alt={item.name}
                                    />
                                  </a>
                                  <span />
                                </div>
                              </div>
                            </li>
                          );

                        return (
                          <li data-rid={2} key={item.id}>
                            <div className="num-box">
                              <span className="num2">{index + 1}</span>
                            </div>
                            <div className="name-box">
                              <a
                                className="name"
                                href={convertRouterStory(item.name, item.id)}
                                title={item.name}
                              >
                                {item.name}
                              </a>
                              <i className="total">{item.countFollow}</i>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-rec-wrap box-center cf">
            <div className="left-wrap fl">
              <h3 className="wrap-title lang">
                Truyện mới
                <a href={`/tim-kiem-tong-hop?${nameRouterSearch.sort}=dateCreated`}>Xem thêm</a>
              </h3>
              <div className="left-info fl">
                <div className="slide-box">
                  <ul>
                    <li>
                      <a href={convertRouterStory(newStories[0]?.name, newStories[0]?.id)}>
                        <img
                          className="lazy"
                          src={getResponsiveImage(newStories[0]?.images?.file, newReiseImg)}
                          alt={newStories[0]?.name}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="info-text">
                  <dl>
                    <dd>
                      <h3>
                        <a href={convertRouterStory(newStories[0]?.name, newStories[0]?.id)}>
                          {newStories[0]?.name}
                        </a>
                      </h3>
                      <p className="author">
                        <a href={convertRouterAuthor(newStories[0]?.storyAuthors?.id)}>
                          {newStories[0]?.storyAuthors?.name}
                        </a>
                      </p>
                      <p className="total">
                        <b>{newStories[0]?.countChapter}</b>
                        <span>chương</span>
                      </p>
                      <p className="intro">{newStories[0]?.description}</p>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="center-book-list fl">
                <div className="line line1" />
                <div className="line line2" />
                <ul>
                  {newStories.slice(1)?.map((item, index) => (
                    <li key={item.id} className={index % 3 === 0 && 'fl-right'}>
                      <div className="book-img">
                        <a href={convertRouterStory(item.name, item.id)}>
                          <img
                            className="lazy"
                            src={getResponsiveImage(item?.images?.file, newReiseImg)}
                            alt={item.name}
                          />
                          {/* <span style={{ opacity: 0 }}>4.4</span> */}
                        </a>
                      </div>
                      <div className="book-info">
                        <h3>
                          <a href={convertRouterStory(item.name, item.id)} title={item.name}>
                            {item.name}
                          </a>
                        </h3>
                        <p>{item.description}</p>
                        <div className="state-box cf">
                          <a
                            href={convertRouterCategory(
                              item.storyCategoriesParent?.urlSlugs,
                              item.storyCategoriesParent?.id
                            )}
                          >
                            <i>{item.storyCategoriesParent?.name || 'N/A'}</i>
                          </a>
                          <a className="author" href={convertRouterAuthor(item?.storyAuthors?.id)}>
                            <img src="/static/truyen/images/user.f22d3.png" />
                            {item.storyAuthors?.name}
                          </a>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="update-wrap box-center mb40 cf">
            <div className="update-rec-wrap fl">
              <h3 className="wrap-title lang">Top Converters</h3>
              <div className="update-rec-list">
                <ul>
                  {topConverters?.map((item, index) => {
                    if (index === 0)
                      return (
                        <li className="wd-f100 pdd-12 unfold no1" key={item.id}>
                          <div className="book-wrap cf">
                            <div className="book-info fl">
                              <h3>NO.1</h3>
                              <h4 className="text-left">
                                <a href={convertRouterConverter(item.id)}>{item.fullname}</a>
                              </h4>
                              <p className="digital text-left">
                                <em>{item.countChapterAll}</em>chương
                              </p>
                            </div>
                            <div className="book-cover">
                              <a className="link" href={convertRouterConverter(item.id)}>
                                <img src={item?.image?.file && getResponsiveImage(item?.image?.file, newReiseImg) || '/static/truyen/images/default-avatar.jpeg'} alt={item.fullname} />
                              </a>
                            </div>
                          </div>
                        </li>
                      );

                    return (
                      <li className={`wd-f100 pdd-12 top-side unfold no${index + 1}`}>
                        {index < 3 && <i className="fa fa-bookmark" aria-hidden="true" />}
                        <span className="no">{index + 1}</span>
                        <a className='numberChuong' href={convertRouterConverter(item.id)}>{item.fullname}</a>
                        <span> {item.countChapterAll}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="update-list-wrap fl">
              <div className="update-tab cf">
                <h3 className="wrap-title lang" style={{ float: 'left' }}>
                  Mới cập nhật
                </h3>
                <p id="update-tab">
                  <a href={`/tim-kiem-tong-hop`} data-eid="qd_A152">
                    Xem thêm
                  </a>
                </p>
              </div>
              <div className="update-list" id="update-list">
                <div className="update-table all">
                  <table width="100%">
                    <colgroup>
                      <col width="90px" />
                      <col width="270px" />
                      <col width="120px" />
                      <col width="150px" />
                      <col width="70px" />
                    </colgroup>
                    <tbody>
                      {updateStories?.map(item => {
                        return (
                          <tr data-rid={1} key={item.id}>
                            <td>
                              <a
                                className="classify"
                                href={convertRouterCategory(
                                  item.storyCategoriesParent?.urlSlugs,
                                  item.storyCategoriesParent?.id
                                )}
                              >
                                {item.storyCategoriesParent?.name}
                              </a>
                            </td>
                            <td>
                              <a
                                className="name"
                                title={item.name}
                                href={convertRouterStory(item.name, item.id)}
                              >
                                {item.name}
                              </a>
                            </td>
                            <td>
                              <a
                                className="section"
                                href={item.newChapter ? convertRouterChapter(
                                  item,
                                  item.newChapter
                                )
                                  : convertRouterStory(item.name, item.id)
                                }
                              >
                                {item.newChapter ? `Chương ${item.newChapter?.number}` : 'N/A'}

                              </a>
                            </td>
                            <td className="respon">
                              <a
                                className="author"
                                href={convertRouterAuthor(item?.storyAuthors?.id)}
                              >
                                {item.storyAuthors?.name}
                              </a>
                            </td>
                            <td className="respon">
                              <i>{moment(item.chapterDateCreated).format('HH:mm DD/MM')}</i>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="god-wrap fr">
              <h3 className="wrap-title lang">Top Tác giả</h3>
              <div className="god-list update-rec-list">
                <ul>
                  {topAuthors?.map((item, index) => {
                    if (index === 0)
                      return (
                        <li className="wd-f100 pdd-12 unfold no1" key={item.id}>
                          <div className="book-wrap cf">
                            <div className="book-info fl">
                              <h3>NO.1</h3>
                              <h4 className="text-left">
                                <a href={convertRouterAuthor(item.id)}>{item.name}</a>
                              </h4>
                              <p className="digital text-left">
                                <em>{item.countChapter}</em>chương
                              </p>
                            </div>
                            <div className="book-cover">
                              <a className="link" href={convertRouterAuthor(item.id)}>
                                <img src={renderLinkImgAvatar(item?.images?.file, newReiseImg)} alt={item.name} />
                              </a>
                            </div>
                          </div>
                        </li>
                      );

                    return (
                      <li className={`wd-f100 pdd-12 unfold top-side no${index + 1}`}>
                        {index < 3 && <i className="fa fa-bookmark" aria-hidden="true" />}
                        <span className="no">{index + 1}</span>
                        {/*img src="https://www.nae.vn/ttv/ttv/public/images_user/159781.png" draggable="false" alt="luoihoc" onerror="this.src='https://truyen.tangthuvien.vn/images/default-avatar.jpeg'" /*/}
                        <a className='numberChuong' href={convertRouterAuthor(item.id)}>{item.name}</a>
                        <span>{item.countChapter} </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          {dataSlidesCenter[0] && (
            <div className="banner-wrap box-center mb30" id="banner-two" data-l1={31}>
              <a
                href={dataSlidesCenter[0].url}
                target="_blank"
                data-eid="qd_A116"
                data-qd_dd_p1={1}
                style={{ display: 'block' }}
              >
                <img
                  src={getResponsiveImage(dataSlidesCenter[0]?.contents?.[0]?.file, [
                    1200,
                    1200,
                    1200,
                  ])}
                />
                <span className="op-tag" />
              </a>
            </div>
          )}

          <div className="finish-book-wrap box-center cf">
            <div className="left-wrap fl">
              <h3 className="wrap-title lang">
                Truyện đã hoàn thành
                <a href={`/tim-kiem-tong-hop?${nameRouterSearch.complete}=1`}>Xem thêm</a>
              </h3>
              <div className="left-info fl">
                <div className="slide-box">
                  <ul>
                    <li>
                      <a
                        href={convertRouterStory(completeStories[0]?.name, completeStories[0]?.id)}
                      >
                        <img
                          className="lazy"
                          src={getResponsiveImage(completeStories[0]?.images?.file, newReiseImg)}
                          alt={completeStories[0]?.name}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="info-text">
                  <dl>
                    <dd>
                      <h3>
                        <a
                          href={convertRouterStory(
                            completeStories[0]?.name,
                            completeStories[0]?.id
                          )}
                        >
                          {completeStories[0]?.name}
                        </a>
                      </h3>
                      <p className="author">
                        <a href={convertRouterAuthor(completeStories[0]?.storyAuthors?.id)}>
                          {completeStories[0]?.storyAuthors?.name}
                        </a>
                      </p>
                      <p className="total">
                        <b>{completeStories[0]?.countChapter}</b>
                        <span>chương</span>
                      </p>
                      <p className="intro">{completeStories[0]?.description}</p>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="center-book-list fl">
                <div className="line line1" />
                <div className="line line2" />
                <ul>
                  {completeStories.slice(1)?.map((item, index) => (
                    <li key={item.id} className={index % 3 === 0 && 'fl-right'}>
                      <div className="book-img">
                        <a href={convertRouterStory(item.name, item.id)}>
                          <img
                            className="lazy"
                            src={getResponsiveImage(item?.images?.file, newReiseImg)}
                            alt={item.name}
                          />
                          {/* <span style={{ opacity: 0 }}>4.4</span> */}
                        </a>
                      </div>
                      <div className="book-info">
                        <h3>
                          <a href={convertRouterStory(item.name, item.id)} title={item.name}>
                            {item.name}
                          </a>
                        </h3>
                        <p>{item.description}</p>
                        <div className="state-box cf">
                          <a
                            href={convertRouterCategory(
                              item.storyCategoriesParent?.urlSlugs,
                              item.storyCategoriesParent?.id
                            )}
                          >
                            <i>{item.storyCategoriesParent?.name || 'N/A'}</i>
                          </a>
                          <a className="author" href={convertRouterAuthor(item?.storyAuthors?.id)}>
                            <img src="/static/truyen/images/user.f22d3.png" />
                            {item.storyAuthors?.name}
                          </a>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="h-pc container-mobile">
          <NewStorys
            name="Truyện mới theo thể loại"
            data={updateStories}
            dispatch={dispatch}
            link='auto'
            filter
          />
          <NewStorys
            name="Truyện xem nhiều"
            hiddenTab
            dispatch={dispatch}
            data={viewStories}
            link={`/tim-kiem-tong-hop?${nameRouterSearch.rank}=view`}
          />
          {dataSlidesCenter[0] && (
            <div className="banner-wrap box-center mb30" id="banner-two" data-l1={31}>
              <a
                href={dataSlidesCenter[0].url}
                target="_blank"
                data-eid="qd_A116"
                data-qd_dd_p1={1}
                style={{ display: 'block' }}
              >
                <img
                  src={getResponsiveImage(dataSlidesCenter[0]?.contents?.[0]?.file, [
                    1200,
                    1200,
                    1200,
                  ])}
                />
                <span className="op-tag" />
              </a>
            </div>
          )}
          <StoryMobile2
            name="Truyện đề cử tháng"
            data={nominateStories}
            link={`/tim-kiem-tong-hop?${nameRouterSearch.rank}=nominate`}
          />
          <StoryMobile2
            name="Truyện đã hoàn thành"
            data={completeStories}
            link={`/tim-kiem-tong-hop?${nameRouterSearch.complete}=1`}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default page(Index);
