import React from 'react';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import {
  convertRouterStory,
} from '@/utils/routerConvert';

function Index({ name, data, link }) {

  const newReiseImg = [70, 70, 70]

  return (
    <div className="block-news module-box mrgtop-12 bg-white wd-f100 pdd-6">
      <div className="module-header wd-f100 pdd-6">
        <h3 className="module-title">{name}</h3>
        <a href={link} className="pull-right">
          Xem thêm <i className="fa fa-angle-right" aria-hidden="true" />
        </a>
      </div>
      <div className="module-list wd-f100 pdd-6 mrgtop-6">
        {(data || [])?.map(item => (
          <div className="book-item wd-f100 mrgbot-12 item-0" key={item.id}>
            <a href={convertRouterStory(item.name, item.id)} className="wd-f100">
              <div className="story-image ">
                <img
                  src={getResponsiveImage(item?.images?.file, newReiseImg)}
                  height="105px"
                  width="100%"
                />
              </div>
              <div className="story-detail wd-f100">
                <h4>
                  {item.name}
                </h4>
                <p className="item-author">Thể loại:  {item.storyCategoriesParent?.name}</p>
                <p className="item-author">Tác giả:  {item.storyAuthors?.name} </p>
                <p className="item-update">Lượt xem:  {item.view}</p>
                {/* <p className="item-update">Tình trạng:  {item.storyAuthors?.name}</p> */}
                <p className="item-update">Số chuơng:  {item.countChapter}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.memo(Index);
